import React from "react";

const TextContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="w-full py-20 sm:py-60 grid place-content-center select-none leading-loose sm:leading-loose font-body text-xl sm:text-3xl antialiased mb-10">
      {children}
    </div>
  );
};

export default TextContainer;
