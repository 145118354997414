import React, { ReactNode, useEffect, useState } from "react";
import { motion } from "framer-motion";

// The children passed to this element should be completely styled elements.
// The carousel will loop through the children and display them in a carousel fashion.
const Carousel = ({ children, reverse }: IProps) => {
  const [play, setPlay] = useState(true);
  const [numElements, setNumElements] = useState(children.length);

  const carouselVariants = {
    start: {
      x: `${reverse ? 0 : -100 * numElements}%`,
      translateX: 0,
    },
    pause: {
      x: 0,
    },
    play: {
      translateX: `${reverse ? "-" : ""}${numElements * 100}%`,
      transition: {
        duration: 40,
        ease: "linear",
        loop: Infinity,
        delay: 1,
      },
    },
  };

  return (
    <motion.div className="w-full p-5 text-white dark:text-stone-dark flex flex-row flex-nowrap overflow-hidden">
      {children.map((child: ReactNode, index: number) => {
        return (
          <motion.div
            key={index}
            variants={carouselVariants}
            initial="start"
            animate={play ? "play" : "pause"}
          >
            {child}
          </motion.div>
        );
      })}
      {children.map((child: ReactNode, index: number) => {
        return (
          <motion.div
            key={index}
            variants={carouselVariants}
            initial="start"
            animate={play ? "play" : "pause"}
          >
            {child}
          </motion.div>
        );
      })}
    </motion.div>
  );
};

interface IProps {
  children: ReactNode[];
  reverse?: boolean;
}

export default Carousel;
