import React from "react";
import { motion } from "framer-motion";
import { slowEase } from "../styles/animations/framer_transitions";
import Button from "../components/Button";
import SEO from "../components/layout_elements/SEO";
import Carousel from "../components/carousel/Carousel";
import { graphql, useStaticQuery } from "gatsby";
import PageLanding from "../components/PageLanding";
import TextContainer from "../components/TextContainer";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const AboutPage = () => {
  const {
    allContentfulTechnology: { nodes: technologies },
  } = useStaticQuery(graphql`
    query MyQuery {
      allContentfulTechnology(filter: { featuredOnProfile: { eq: true } }) {
        nodes {
          id
          name
          logo {
            publicUrl
            file {
              url
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="About Me | Zach Mumbauer" description="" />
      <PageLanding>About Me</PageLanding>
      <TextContainer>
        <p>
          I am a detail-oriented full-stack software engineer with a bachelor's
          degree in computer science from{" "}
          <OutboundLink
            href="https://www.gwu.edu/"
            className="link"
            target="_blank"
          >
            George Washington University
          </OutboundLink>
          , specializing in computer security and information assurance. If
          you're interested in my full set of credentials, please check out my{" "}
          <a
            href="https://www.linkedin.com/in/zmumbauer/"
            className="link"
            target="_blank"
          >
            LinkedIn
          </a>
          .
        </p>
      </TextContainer>

      <TextContainer>
        <p>
          I have worked for companies large and small, but one thing is
          consistent: <br />
          <span className="block text-center mb-20">
            Improving the world through technology.
          </span>
        </p>
        <div className="m-auto">
          <Button link="/resume/">Check out my resume</Button>
        </div>
      </TextContainer>

      <div className="">
        <h1 className="font-body text-lg text-center select-none mb-5">
          This website was built standing on the shoulders of giants
        </h1>
        <Carousel>
          {technologies.map((technology: Technology, index: Number) => {
            const { name, logo } = technology;
            return (
              <img
                className="h-10 block mx-20 brightness-0 dark:invert grayscale select-none"
                draggable={false}
                src={logo.file.url}
                alt={name}
                key={index.toString()}
              />
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

interface Technology {
  id: string;
  name: string;
  logo: {
    publicUrl: string;
    file: {
      url: string;
    };
  };
}

export default AboutPage;
