import React from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby";

const Button = ({ children, link = "#" }: IProps) => {
  const buttonVariants = {
    hover: {
      scale: 1.1,
    },
  };

  return (
    <Link to={link}>
      <motion.button
        variants={buttonVariants}
        whileHover="hover"
        className="py-2 px-3 text-lg outline outline-offset-2 text-stone-dark dark:text-white dark:outline-white outline-stone-dark bg-transparent rounded-full max-w-full hover:cursor-pointer"
        aria-label={`Button link to ${link ? link : "the current page"}`}
      >
        {children}
      </motion.button>
    </Link>
  );
};

interface IProps {
  children: React.ReactNode;
  link?: string;
}

export default Button;
